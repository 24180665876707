import styled from 'styled-components';

type AvatarProps = {
  maxWidth?: number;
  borderRadius?: number;
};

const Avatar = styled.img<AvatarProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '0')};
  height: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
`;

export default Avatar;
