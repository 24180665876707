import { getAosRelatedSocs } from 'components/AddSOCForm/AddSOCForm.helpers';
import { DropdownValue, FormikSetFieldValue } from 'models/global';
import { ItemInput } from 'models/itemInput';
import { Dispatch, SetStateAction } from 'react';
import { Options } from 'react-select';
import {
  areasOfPracticeVar,
  areasOfSpecialisationVar,
  sitesOfCareVar,
} from 'services/apollo/typePolicies/queryPolicies';
import { logError } from 'services/logging';
import { idsToObjectValues } from 'utils/dataTransforms';
import { splitZipcodes } from 'utils/splitZipcodes';
import { Employee, GetCompaniesOutput } from '../../../../types/graphql';
import {
  EmployeeMutationType,
  AddEmployeeFormValues,
  StateMutationType,
} from './AddEmployeeModal.model';

export const handleOnSubmitErrors = (
  error: any,
  setServerError: Dispatch<SetStateAction<string | null>>,
): void => {
  if (error.graphQLErrors[0].message) {
    setServerError(error.graphQLErrors[0].message);
  } else {
    setServerError('Oops, something went wrong. Please try again');
  }
  logError({ error, errorName: 'AddEmployeeModalError' });
};

export const formatZipCodesForMutation = (zipCodes: string): string[] =>
  zipCodes === '' ? [] : splitZipcodes(zipCodes);

export const formatStatesForMutation = (states: Options<DropdownValue>): StateMutationType[] =>
  states.map((state: DropdownValue) => ({
    code: `US#${state.value}`,
    name: state.label,
  }));

export const formatDataForMutation = (values: AddEmployeeFormValues): EmployeeMutationType => ({
  sitesOfCareIds: values.sitesOfCare.map(soc => soc.id),
  areasOfPracticeIds: values.areasOfPractice.map(aop => aop.id),
  areasOfSpecialisationIds: values.areasOfSpecialisation.map(aos => aos.id),
  firstName: values.firstName.trim(),
  lastName: values.lastName.trim(),
  jobTitle: values.jobTitle.trim(),
  email: values.email.trim(),
  zipCodes: formatZipCodesForMutation(values.zipCodes),
  states: formatStatesForMutation(values.states),
});

export const handleSelect = (
  setFieldValue: FormikSetFieldValue,
  selected: any,
  fullList: ItemInput[] | undefined,
  field: string,
): void => {
  if (selected && selected.length > 0 && fullList) {
    const newValues = fullList.filter(item =>
      selected.find((option: any) => option.value === item.id),
    );
    setFieldValue(field, newValues, true);
  } else {
    setFieldValue(field, [], true);
  }
};

export const handleStateSelect = (setFieldValue: FormikSetFieldValue, options: any): void => {
  if (options && options.length > 0) {
    setFieldValue('states', options, true);
  } else {
    setFieldValue('states', [], true);
  }
};

export const getInitialFormState = (
  type: 'aos' | 'aop' | 'soc',
  company?: { getCompany: GetCompaniesOutput },
  employee?: Employee,
) => {
  // Wait till company data loads
  if (!company) {
    return [];
  }

  const aos = idsToObjectValues(
    company.getCompany.areasOfSpecialisationIds,
    areasOfSpecialisationVar(),
  );

  if (type === 'aos') {
    // AOS employee edit
    if (employee) {
      return idsToObjectValues(employee.areasOfSpecialisationIds, areasOfSpecialisationVar());
    }
    // AOS add employee
    return aos;
  }

  if (type === 'soc') {
    // SOC employee edit
    if (employee) {
      return idsToObjectValues(employee.sitesOfCareIds, sitesOfCareVar());
    }
    // AOS add employee
    return getAosRelatedSocs(aos);
  }

  // AOP employee edit
  if (employee) {
    return idsToObjectValues(employee.areasOfPracticeIds, areasOfPracticeVar());
  }
  // AOP add employee
  return idsToObjectValues(company.getCompany.areasOfPracticeIds, areasOfPracticeVar());
};
