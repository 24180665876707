import { gql } from '@apollo/client';

export const getAreasOfPracticeAndSitesOfCare = gql`
  query getAreasOfPracticeAndSitesOfCare($businessUnitAdminId: String!) {
    getBusinessUnitAdminByCognitoId(businessUnitAdminId: $businessUnitAdminId) {
      businessUnitId
      companyId
    }
    getSitesOfCare {
      id
      name {
        en
      }
    }
    getAreasOfPractice {
      id
      name {
        en
      }
    }
    getAreasOfSpecialisation {
      areaOfPracticeIds
      roleIds
      siteOfCareIds
      id
      name {
        en
      }
    }
  }
`;
