import React, { useCallback, useState } from 'react';
import { Input } from 'atoms/Input/Input';
import { Button } from 'atoms/Button/Button';
import MultiSelect from 'atoms/MultiSelect/MultiSelect';
import { TextArea } from 'atoms/TextArea/TextArea';
import { useFormik } from 'formik';
import { Field } from 'atoms/Field/Field';
import { AddCompanyEmployeeSchema } from './AddEmployeeModal.schema';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { getAreasOfPracticeAndSitesOfCare } from 'queries/areasOfPracticeAndSitesOfCare';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { putEmployeesMutation } from 'mutations/putEmployeesMutation';
import { updateEmployeeClinicalAttribute } from 'mutations/updateEmployeeClinicalAttribute';
import { splitZipcodes } from 'utils/splitZipcodes';
import { getEmployees } from 'queries/employees';
import { getEmployee } from 'queries/employee';
import { usStates } from 'constants/usStates';
import { formatForDropdown } from 'utils/formatForDropdown/formatForDropdown';
import { TEST_IDS } from 'constants/testIds';
import { idsToObjectValues } from 'utils/dataTransforms';
import { CustomEventName, logTrackingEvent } from 'services/analytics';
import { Header } from 'atoms/Header/Header';
import { TextButton } from 'atoms/TextButton/TextButton';
import { useBusinessUnit } from 'hooks/useBusinessUnit/useBusinessUnit';
import { ModalHeader } from 'atoms/ModalHeader/ModalHeader';
import { AddAOSForm, AOSFormValues } from 'components/AddAOSForm/AddAOSForm';
import { AddSOCForm, SOCFormValues } from 'components/AddSOCForm/AddSOCForm';
import {
  areasOfSpecialisationVar,
  sitesOfCareVar,
} from 'services/apollo/typePolicies/queryPolicies';
import {
  formatDataForMutation,
  formatStatesForMutation,
  formatZipCodesForMutation,
  getInitialFormState,
  handleOnSubmitErrors,
  handleStateSelect,
} from './AddEmployeeModal.helpers';
import {
  StyledForm,
  SuccessWrapper,
  SuccessTitle,
  AllStates,
  Row,
  ButtonWrapper,
  ServerError,
  Wrapper,
} from './styles';
import {
  AddEmployeeModalProps,
  AddEmployeeFormValues,
  MutationTypes,
  QueryTypes,
  UpdateEmployeeMutation,
} from './AddEmployeeModal.model';
import { ItemInput } from 'models/itemInput';
import EditableRow from 'atoms/EditableRow';
import { updateAos } from 'utils/updateAos';
import { getAosRelatedSocs } from 'components/AddSOCForm/AddSOCForm.helpers';
import { getCompany } from 'queries/company';
import { FrontendState } from '../../../../types/graphql';
import { DropdownValue } from 'models/global';

export const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({ employee }) => {
  const { user } = useAuth();
  const { businessUnitId, companyId } = useBusinessUnit();
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const [showAddAOS, setShowAddAOS] = useState(false);
  const [showAddSOC, setShowAddSOC] = useState(false);
  const [editAos, setEditAos] = useState<AOSFormValues | null>(null);

  const { data } = useQuery<QueryTypes>(getAreasOfPracticeAndSitesOfCare, {
    variables: {
      businessUnitAdminId: user && user.auth.sub,
    },
  });

  const { data: companyData } = useQuery(getCompany, {
    variables: { companyId },
  });

  const [putEmployees] = useMutation<MutationTypes>(putEmployeesMutation, {
    refetchQueries: [
      {
        query: getEmployees,
        variables: {
          businessUnitId,
          limit: 500,
        },
      },
    ],
  });

  const [updateEmployee] = useMutation<UpdateEmployeeMutation>(updateEmployeeClinicalAttribute, {
    refetchQueries: [
      {
        query: getEmployee,
        variables: {
          employeeId: employee?.id,
        },
      },
      {
        query: getEmployees,
        variables: {
          businessUnitId,
          limit: 500,
        },
      },
    ],
  });

  const initialValues: AddEmployeeFormValues = {
    firstName: employee?.firstName || '',
    lastName: employee?.lastName || '',
    email: employee?.email || '',
    jobTitle: employee?.jobTitle || '',
    areasOfPractice: getInitialFormState('aop', companyData, employee),
    sitesOfCare: getInitialFormState('soc', companyData, employee),
    // TOOD: add states from company obj
    states: employee
      ? (employee.state.map((state: FrontendState) =>
          usStates.find(s => s.label === state.name.en),
        ) as DropdownValue[])
      : [],
    zipCodes: employee?.zipcode.join(', ') || '',
    areasOfSpecialisation: getInitialFormState('aos', companyData, employee),
  };

  const { handleSubmit, handleChange, values, isSubmitting, isValid, setFieldValue, resetForm } =
    useFormik({
      initialValues,
      validateOnMount: true,
      onSubmit: async values => {
        setServerError(null);

        if (data) {
          const event: CustomEventName = employee
            ? CustomEventName.EmployeeUpdated
            : CustomEventName.EmployeeCreated;
          try {
            if (employee) {
              await updateEmployee({
                variables: {
                  employeeId: employee.id,
                  firstName: values.firstName.trim(),
                  lastName: values.lastName.trim(),
                  jobTitle: values.jobTitle.trim(),
                  AOPIds: values.areasOfPractice.map(aop => aop.id),
                  sitesOfCareIds: values.sitesOfCare.map(soc => soc.id),
                  areasOfSpecialisationIds: values.areasOfSpecialisation.map(aos => aos.id),
                  states: formatStatesForMutation(values.states),
                  zipcodes: formatZipCodesForMutation(values.zipCodes),
                },
              });
            } else {
              const employees = formatDataForMutation(values);

              await putEmployees({
                variables: {
                  businessUnitId,
                  companyId,
                  employees,
                },
              });
            }

            logTrackingEvent({ name: event });
            resetForm({});
            setSuccess(true);
          } catch (error) {
            handleOnSubmitErrors(error as ApolloError, setServerError);
          }
        }
      },
      validationSchema: AddCompanyEmployeeSchema,
    });

  const onSetAos = useCallback(
    (aosValues: AOSFormValues) => {
      updateAos(aosValues, values, setFieldValue);
    },
    [setFieldValue, values],
  );

  const onDeleteAos = useCallback(
    (id: string) => {
      const updatedAos = values.areasOfSpecialisation.filter(item => item.id !== id);
      const updatedAops = values.areasOfPractice.filter(item => item.areaOfSpecialisationId !== id);
      setFieldValue('areasOfSpecialisation', updatedAos);
      setFieldValue('areasOfPractice', updatedAops);
      setFieldValue(
        'sitesOfCare',
        getAosRelatedSocs(updatedAos, {
          aos: values.areasOfSpecialisation,
          soc: values.sitesOfCare,
        }),
      );
    },
    [values],
  );

  const onEditClick = useCallback(
    (aosItem: ItemInput) => {
      setShowAddAOS(true);

      setEditAos({
        aos: formatForDropdown([aosItem]),
        productGroups: formatForDropdown(
          values.areasOfPractice.filter(item => item.areaOfSpecialisationId === aosItem.id),
        ),
      });
    },
    [values],
  );

  const onEditSOC = useCallback(() => {
    setShowAddSOC(true);
  }, []);

  const onAddSOC = useCallback((socs: SOCFormValues) => {
    setShowAddSOC(false);
    setFieldValue('sitesOfCare', idsToObjectValues(socs.soc, sitesOfCareVar()));
  }, []);

  if (success) {
    return (
      <Wrapper>
        <ModalHeader employee={employee} />
        <SuccessWrapper>
          <SuccessTitle>Employee {employee ? 'updated!' : 'invitation sent!'}</SuccessTitle>
          <ButtonWrapper>
            <Button text="Add another Employee" block onClick={() => setSuccess(false)} />
          </ButtonWrapper>
        </SuccessWrapper>
      </Wrapper>
    );
  }

  if (showAddAOS && companyData) {
    return (
      <Wrapper>
        <AddAOSForm
          currentAosValues={values.areasOfSpecialisation}
          setValues={onSetAos}
          editAos={editAos}
          aosData={idsToObjectValues(
            companyData.getCompany.areasOfSpecialisationIds,
            areasOfSpecialisationVar(),
          )}
          areasOfPracticeIds={companyData.getCompany.areasOfPracticeIds}
          isEmployee
          onBackClick={() => {
            setShowAddAOS(false);
            setEditAos(null);
          }}
        />
      </Wrapper>
    );
  }

  if (showAddSOC) {
    return (
      <Wrapper>
        <AddSOCForm
          currentAosValues={values.areasOfSpecialisation}
          currentSocValues={values.sitesOfCare}
          onAddSOC={onAddSOC}
          setShowAddSOC={setShowAddSOC}
          currentSocIds={values.sitesOfCare.map(soc => soc.id)}
        />
      </Wrapper>
    );
  }

  const socDisabled = values.areasOfSpecialisation.length === 0;

  return (
    <Wrapper data-testid={TEST_IDS.addEmployeesModal}>
      <ModalHeader employee={employee} />
      <StyledForm onSubmit={handleSubmit} autoComplete="off">
        <Row>
          <Field>
            <Input
              data-testid={TEST_IDS.addEmployeeModal.firstName}
              name="firstName"
              type="text"
              placeholder="First Name"
              onChange={handleChange}
              value={values.firstName}
            />
          </Field>
          <Field>
            <Input
              data-testid={TEST_IDS.addEmployeeModal.lastName}
              name="lastName"
              type="text"
              placeholder="Last Name"
              onChange={handleChange}
              value={values.lastName}
            />
          </Field>
        </Row>
        <Row>
          <Field>
            <Input
              disabled={!!employee}
              data-testid={TEST_IDS.addEmployeeModal.email}
              name="email"
              type="text"
              placeholder="Email"
              onChange={handleChange}
              value={values.email}
            />
          </Field>
          <Field>
            <Input
              data-testid={TEST_IDS.addEmployeeModal.jobTitle}
              name="jobTitle"
              type="text"
              placeholder="Job Title"
              onChange={handleChange}
              value={values.jobTitle}
            />
          </Field>
        </Row>
        <Field>
          <Header
            title="Areas of specialization"
            subtitle="Add areas of specialization that the employee covers"
          />
          {values.areasOfSpecialisation[0] &&
            values.areasOfSpecialisation.map(item => (
              <EditableRow
                key={item.id}
                title={item.name.en}
                subtitle={(() => {
                  const productGroups = values.areasOfPractice.filter(
                    aop => aop.areaOfSpecialisationId === item.id,
                  );
                  return `${productGroups.length} product group${
                    productGroups.length === 1 ? '' : 's'
                  }`;
                })()}
                onDeleteClick={() => onDeleteAos(item.id)}
                onEditClick={() => onEditClick(item)}
              />
            ))}
          {companyData &&
            companyData.getCompany.areasOfSpecialisationIds.length !==
              values.areasOfSpecialisation.length && (
              <TextButton
                title="Add new area of specialization"
                onClick={() => setShowAddAOS(true)}
                testId={TEST_IDS.addEmployeeModal.addAosButton}
              />
            )}
        </Field>
        <Field>
          <Header
            disabled={socDisabled}
            title="Sites of care"
            subtitle="Choose sites of care that the employee covers"
          />
          {values.sitesOfCare.length > 0 && !socDisabled && (
            <EditableRow
              title="Customize"
              subtitle={`currently ${values.sitesOfCare.length} sites of care`}
              onEditClick={onEditSOC}
              testId={TEST_IDS.addEmployeeModal.sitesOfCare}
            />
          )}
          <TextButton
            disabled={socDisabled}
            title="Add sites of care"
            onClick={socDisabled ? () => {} : () => setShowAddSOC(true)}
          />
        </Field>
        <Field>
          <Header title="States" />
          <MultiSelect
            value={values.states}
            name="locations"
            handleSelect={selectedOptions => handleStateSelect(setFieldValue, selectedOptions)}
            options={usStates}
          />
          {values.states.length !== usStates.length && (
            <AllStates onClick={() => handleStateSelect(setFieldValue, usStates)}>
              Add all US States
            </AllStates>
          )}
        </Field>
        <Field>
          <TextArea
            testId={TEST_IDS.addEmployeeModal.zipcodes}
            value={values.zipCodes}
            name="zipCodes"
            label={`Zipcodes (${
              !!values.zipCodes ? splitZipcodes(values.zipCodes).length : 0
            } of 2000)`}
            rows={6}
            onChange={handleChange}
          />
        </Field>

        <ServerError>{serverError}</ServerError>
        <Button
          text={isSubmitting ? 'Loading...' : employee ? 'Update' : 'Create employee'}
          block
          data-testid={TEST_IDS.addEmployeeModal.createButton}
          type="submit"
          disabled={!isValid || isSubmitting}
        />
      </StyledForm>
    </Wrapper>
  );
};
