import { splitZipcodes } from 'utils/splitZipcodes';
import * as Yup from 'yup';

export const AddCompanyEmployeeSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  jobTitle: Yup.string().trim().required('Required'),
  email: Yup.string().trim().email('Invalid email').required('Required'),
  areasOfPractice: Yup.array().min(1, 'Please select at least 1 area of practice'),
  sitesOfCare: Yup.array().min(1, 'Please select at least 1 site of care'),
  states: Yup.array().test(
    'Valid States',
    'Select either a state or enter valid zipcodes',
    function (value) {
      const { zipCodes } = this.parent;

      const zipCodesValid = (data: string) => {
        const zipcodes = splitZipcodes(data);
        for (let i = 0; i < zipcodes.length; i++) {
          if (!/^[0-9]{5,5}$/.test(zipcodes[i])) {
            return false;
          }
        }
        return true;
      };

      // if states exist, zipcodes exist and zipcodes are valid
      if (value && value.length > 0 && zipCodes && zipCodesValid(zipCodes)) return true;

      // If no states but zipcodes exist and are valid
      if (value && value.length === 0 && zipCodes && zipCodesValid(zipCodes)) return true;

      // If states exist but zipcodes are empty
      if (
        (value && value.length > 0 && !zipCodes) ||
        (value && value.length > 0 && zipCodes.length === 0)
      )
        return true;

      // If no states and no zipcodes or no states but zipcodes exist and are invalid
      return false;
    },
  ),
});
