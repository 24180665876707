import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 90vh;
  width: ${defaultTheme.spacing(160)};
  background-color: ${defaultTheme.colors.white};
  border-radius: 5px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${defaultTheme.spacing(10)};
  height: calc(90vh - ${defaultTheme.spacing(19)});
  overflow: auto;
`;

export const Loading = styled.p`
  font-size: 13px;
`;

export const SuccessWrapper = styled.div`
  padding: ${defaultTheme.spacing(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessTitle = styled.h3`
  font-size: 22px;
  font-weight: 400;
  margin-bottom: ${defaultTheme.spacing(8)};
`;

export const AllStates = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.healthBlue};
  margin-top: ${defaultTheme.spacing(2)};
  padding: 0 ${defaultTheme.spacing(2)};
  font-weight: 500;
  letter-spacing: -0.03em;
  &:hover {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  > div {
    max-width: calc(50% - ${defaultTheme.spacing(3)});
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 200px;
`;

export const ServerError = styled.p`
  text-align: center;
  display: block;
  margin: 0 0 ${defaultTheme.spacing(4)};
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.alertRed};
`;
