import { OperationVariables, useMutation, useQuery } from '@apollo/client';
import { BUAdminQueryType } from 'components/CompanyDetails/CompanyDetails.model';
import { archiveEmployee } from 'mutations/archiveEmployee';
import { deleteBusinessUnit } from 'mutations/deleteBusinessUnit';
import { deleteBusinessUnitAdmin } from 'mutations/deleteBusinessUnitAdmin';
import { deleteCompany } from 'mutations/deleteCompany';
import { getBusinessUnitAdmins } from 'queries/company';
import { archiveClinician } from 'mutations/archiveClinician';

export const useGetBusinessUnitAdmins = (options: OperationVariables) => {
  return useQuery<BUAdminQueryType>(getBusinessUnitAdmins, options);
};

export const useDeleteBusinessUnitAdmin = (options?: OperationVariables) => {
  return useMutation(deleteBusinessUnitAdmin, options);
};

export const useArchiveClinician = (options?: OperationVariables) => {
  return useMutation(archiveClinician, options);
};

export const useArchiveEmployee = (options?: OperationVariables) => {
  return useMutation(archiveEmployee, options);
};

export const useDeleteBusinessUnit = (options?: OperationVariables) => {
  return useMutation(deleteBusinessUnit, options);
};

export const useDeleteCompany = (options?: OperationVariables) => {
  return useMutation(deleteCompany, options);
};
