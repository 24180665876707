import { gql } from '@apollo/client';

export const updateEmployeeClinicalAttribute = gql`
  mutation updateEmployeeClinicalAttribute(
    $employeeId: String!
    $AOPIds: [String!]!
    $jobTitle: String!
    $firstName: String!
    $lastName: String!
    $sitesOfCareIds: [String!]!
    $areasOfSpecialisationIds: [String!]!
    $states: [StateInput!]!
    $zipcodes: [String!]!
  ) {
    updateEmployeeClinicalAttribute(
      employeeId: $employeeId
      AOPIds: $AOPIds
      sitesOfCareIds: $sitesOfCareIds
      areasOfSpecialisationIds: $areasOfSpecialisationIds
      jobTitle: $jobTitle
      firstName: $firstName
      lastName: $lastName
      states: $states
      zipcodes: $zipcodes
    ) {
      employeeId
    }
  }
`;
