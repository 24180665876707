import React from 'react';
import { Checkbox } from 'atoms/Checkbox/Checkbox';
import { DropdownValue } from 'models/global';
import { Styles } from './styles';
import { TEST_IDS } from 'constants/testIds';
import { Text } from 'atoms/Text/Text';

type CheckboxesProps = {
  options: DropdownValue[];
  selected: string[];
  handleChange: (value: string) => void;
  initialColumns: number;
  maxWidth?: string;
  inline?: boolean;
  label?: string;
};

export const Checkboxes: React.FC<CheckboxesProps> = ({
  options,
  selected,
  handleChange,
  initialColumns,
  maxWidth,
  inline,
  label,
}) => {
  return (
    <Styles.Wrapper inline={!!inline} maxWidth={maxWidth}>
      {label && <Text.button2>{label}</Text.button2>}
      <Styles.Content data-testid={TEST_IDS.atoms.checkboxes.title} initialColumns={initialColumns}>
        {options.map((option, i) => {
          const checked = selected.includes(option.value);
          const last = i % initialColumns === 0;
          return (
            <Checkbox
              key={`${option.value}-${i}`}
              label={option.label}
              onChange={handleChange}
              checked={checked}
              name={option.label}
              value={option.value}
              last={last}
            />
          );
        })}
      </Styles.Content>
    </Styles.Wrapper>
  );
};
