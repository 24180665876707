import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Header = styled.div`
  padding: ${getSpacing(4)} ${getSpacing(6)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  border-radius: 4px 4px 0 0;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.deepBlue};
  font-size: 16px;
  line-height: 150%;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Icon = styled.svg`
  margin-right: ${getSpacing(4)};
`;

export const Close = styled.div`
  font-size: 13px;
  cursor: pointer;
`;
