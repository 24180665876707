import React, { useCallback } from 'react';
import Card from 'atoms/Card';
import { getSpacing } from 'stylesheet';
import Avatar from 'atoms/Avatar';
import Tag from 'atoms/Tag';
import { Loader } from 'atoms/Loader/Loader';
import { TEST_IDS } from 'constants/testIds';
import { idsToStringValues } from 'utils/dataTransforms';
import { AddEmployeeModal } from 'components/AddEmployeeModal/AddEmployeeModal';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { Styles } from './styles';
import UserStatusIcon from 'components/UserStatusIcon';
import { useQuery } from '@apollo/client';
import { getClinicalAttributes } from 'queries/clinicalAttributes';
import { areasOfPracticeVar, sitesOfCareVar } from 'services/apollo/typePolicies/queryPolicies';
import { Accordion } from 'atoms/Accordion/Accordion';
import { ItemInput } from 'models/itemInput';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { client } from 'services/client';
import { displayAlert } from 'services/alert/alert';
import { Employee, FrontendState } from '../../../../types/graphql';
import { useArchiveEmployee } from 'hooks/api/api';
import { TextButton } from 'atoms/TextButton/TextButton';
import { defaultTheme } from 'constants/theme/defaultTheme';

type ProfileDetailsProps = {
  data: { getEmployee: Employee };
  loading: boolean;
  refetchData?: (employeeId: string) => void;
};

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({ data, loading, refetchData }) => {
  const { handleModal } = useModal();
  const { user } = useAuth();
  const [archiveEmployeeCall] = useArchiveEmployee({
    onError: () => alert('There has been an error. Please try again'),
    onCompleted: (data: { archiveEmployee: Employee }) => {
      refetchData && refetchData(data.archiveEmployee.id);
      alert(
        `${data.archiveEmployee.firstName} ${data.archiveEmployee.lastName} has been successfully archived`,
      );
    },
  });

  const { loading: isAttrsLoading, data: clinicalData } = useQuery(getClinicalAttributes);
  const renderEditButton = () => {
    if (user?.isVTailAdmin) {
      return null;
    }
    return (
      <Styles.HeaderActions
        onClick={() => handleModal(<AddEmployeeModal employee={data.getEmployee} />)}
      >
        Edit
      </Styles.HeaderActions>
    );
  };

  const resendInviteEmail = async (employeeId: string, email: string) => {
    try {
      await client.post('user/resendInviteEmail', { employeeId, email });
      displayAlert(`A new invite has been sent to ${email}`);
    } catch (error) {
      displayAlert(`There was an error resending the invite`);
    }
  };

  const onArchiveEmployee = useCallback((employee: Employee) => {
    if (confirm(`Are you sure you want to archive ${employee.firstName} ${employee.lastName}?`)) {
      archiveEmployeeCall({ variables: { employeeId: employee.id } });
    }
  }, []);

  const renderBody = () => {
    if (loading || isAttrsLoading) return <Loader />;

    if (!data?.getEmployee?.firstName)
      return (
        <Styles.Message>Select an employee from the table to view their details</Styles.Message>
      );

    const {
      firstName,
      lastName,
      jobTitle,
      email,
      areasOfPracticeIds,
      sitesOfCareIds,
      state,
      zipcode,
      userStatus,
      id,
      areasOfSpecialisationIds,
    } = data.getEmployee;

    const showResendInvite = (user?.isVTailAdmin || user?.isBUAdmin) && userStatus !== 'Activated';

    return (
      <>
        <Styles.Header>
          <Styles.Person>
            <Avatar
              src={`https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}&font-size=0.25&background=F6F6F7&color=838394`}
              maxWidth={48}
              borderRadius={30}
            />
            <Styles.Meta>
              <Styles.H2>
                {firstName} {lastName}
              </Styles.H2>
              <Styles.Position>{jobTitle}</Styles.Position>
            </Styles.Meta>
          </Styles.Person>
          {renderEditButton()}
        </Styles.Header>
        <Styles.Content>
          <Styles.Row>
            <Styles.Field>
              <Styles.Label>Email</Styles.Label>
              <Styles.Value>{email}</Styles.Value>
              {showResendInvite && (
                <Styles.ResendLink
                  data-testid="resend-link"
                  onClick={() => resendInviteEmail(id, email)}
                >
                  Resend invite email
                </Styles.ResendLink>
              )}
            </Styles.Field>
          </Styles.Row>

          {areasOfSpecialisationIds && areasOfSpecialisationIds[0] ? (
            <>
              <Styles.Label>Areas of Specialization</Styles.Label>
              {areasOfSpecialisationIds.map((aosId: string) => {
                const aos = clinicalData.getAreasOfSpecialisation.find(
                  (item: ItemInput) => item.id === aosId,
                );
                const areasOfPractice = areasOfPracticeIds.filter((item: string) =>
                  aos.areaOfPracticeIds.includes(item),
                );
                return (
                  <Styles.Row key={aos.id}>
                    <Accordion title={aos.name.en} defaultExpanded>
                      <Styles.Label>Product Groups</Styles.Label>
                      <Styles.Tags>
                        {idsToStringValues(areasOfPractice, areasOfPracticeVar())
                          .sort((a: string, b: string) => (a < b ? -1 : 1))
                          .map((areaOfPractice: string, i: number) => {
                            return <Tag key={i}>{areaOfPractice}</Tag>;
                          })}
                      </Styles.Tags>
                    </Accordion>
                  </Styles.Row>
                );
              })}
            </>
          ) : (
            <Styles.Row>
              <Styles.Field>
                <Styles.Label>Clinical areas of coverage</Styles.Label>
                <Styles.Tags>
                  {idsToStringValues(areasOfPracticeIds, areasOfPracticeVar())
                    .sort((a: string, b: string) => (a < b ? -1 : 1))
                    .map((areaOfPractice: string, i: number) => {
                      return <Tag key={i}>{areaOfPractice}</Tag>;
                    })}
                </Styles.Tags>
              </Styles.Field>
            </Styles.Row>
          )}
          <Styles.Row>
            <Styles.Field>
              <Styles.Label>Sites of care</Styles.Label>
              <Styles.Tags data-testid={'site-of-care-details-item'}>
                {idsToStringValues(sitesOfCareIds, sitesOfCareVar())
                  .sort((a: string, b: string) => (a < b ? -1 : 1))
                  .map((siteOfCare: string, i: number) => {
                    return <Tag key={i}>{siteOfCare}</Tag>;
                  })}
              </Styles.Tags>
            </Styles.Field>
          </Styles.Row>
          <Styles.Row>
            <Styles.Field>
              <Styles.Label>States</Styles.Label>
              {state.length ? (
                <Styles.Tags>
                  {[...state]
                    .sort((a: FrontendState, b: FrontendState) => (a.name.en < b.name.en ? -1 : 1))
                    .map((s: FrontendState, i: number) => {
                      return <Tag key={i}>{s.name.en}</Tag>;
                    })}
                </Styles.Tags>
              ) : (
                <Styles.Value>None</Styles.Value>
              )}
            </Styles.Field>
          </Styles.Row>
          <Styles.Row>
            <Styles.Field>
              <Styles.Label>Specific Zipcodes</Styles.Label>
              {zipcode.length ? (
                <Styles.Tags>
                  {[...zipcode].map((zip: string, i: number) => {
                    return <Tag key={i}>{zip}</Tag>;
                  })}
                </Styles.Tags>
              ) : (
                <Styles.Value>None</Styles.Value>
              )}
            </Styles.Field>
          </Styles.Row>
          {userStatus && (
            <Styles.Row>
              <Styles.Field>
                <Styles.Label>Account Status</Styles.Label>
                <Styles.IconRow>
                  <UserStatusIcon id={`profile_details_${id}`} employee={data.getEmployee} />
                  <Styles.IconValue>{userStatus}</Styles.IconValue>
                </Styles.IconRow>
              </Styles.Field>
            </Styles.Row>
          )}
          {user?.isVTailAdmin && (
            <TextButton
              onClick={() => onArchiveEmployee(data.getEmployee)}
              data-testid={TEST_IDS.profileDetails.archiveButton}
              title="Archive employee"
              noSvg
              color={defaultTheme.colors.alertRed}
            />
          )}
        </Styles.Content>
      </>
    );
  };

  return (
    <Card data-testid={TEST_IDS.employeeDetails} margin={`0 0 0 ${getSpacing(6)}`} flex={2}>
      {renderBody()}
    </Card>
  );
};
