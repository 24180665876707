import { gql } from '@apollo/client';

export const archiveClinician = gql`
  mutation archiveClinician($clinicianId: String!) {
    archiveClinician(clinicianId: $clinicianId) {
      id
      firstName
      lastName
    }
  }
`;
